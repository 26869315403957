<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; text-align:center;" class="intro-header">
            <div class="header-text">
                <div
                    style="position: absolute;background-color: rgba(32, 36, 53, 0.55); width: 100%; height: 100%; top: 0px; left: 0px; z-index: 188;">
                </div>
                <div style="color: #ffffff; font-size: 32px; font-weight: bold; z-index: 199; position: relative;"
                    class="header-title">
                    公司简介
                </div>
                <div style="color: #999999;z-index: 199; position: relative;">
                    Disclaimers
                </div>
            </div>
        </div>
        <div style="width: 1200px; margin: 0 auto; padding: 0px 18px;">
            <div
                style="color: #3D3D3D; font-size: 24px; margin-top: 40px; margin-bottom: 44px; padding-bottom: 16px; border-bottom: 1px solid #eeeeee;">
                公司简介
            </div>
            <div>
                <p>广州市科颐轩电子科技有限公司(http://kyx.zkshare.com/)成立于2013年，是一家先进的物联网、互联网技术和实验现代化管理理念相结合的高新技术企业，长期以来专注于实验室管理领域，推出了国内领先的高新技术产品
                    ⸺ 科颐轩实验室智能管理系统。</p>
                <br />
                <p>科颐轩是中国医药生物技术协会、广东省医学会、广东省医院协会实验室建设与管理分会重点推荐品牌，公司获得高新技术企业、高新技术产品、守合同重信用企业、多种著作权及专利等多类资质证书。</p>
                <br />
                <p> 科颐轩公司在医科类科研领域深耕多年，在业界拥有良好口碑。截止2021年，引进并使用科颐轩实验室智能管理系统，具有相当规模以及影响力的用户超过100家，其中包括钟南山、侯凡凡院士领衔的国家重点实验室、眼科学国家重点实验室、高等院校以及大型重点三甲医院实验室。
                </p>
            </div>
            <div>
                <div
                    style="background: #FFFFFF;box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16); padding: 35px 280px; display: flex; justify-content: space-between;border-radius: 8px; margin-top: 80px;">
                    <div style="text-align: center;">
                        <div style="display: flex; align-items: center;">
                            <div class="color-primary" style="font-size: 60px; font-weight: bold;">
                                100
                            </div>
                            <div
                                style="display: flex; flex-direction: column; justify-content: space-between; margin-left: 3px;">
                                <div class="color-primary" style="font-size: 18px;"> + </div>
                                <div class="color-primary" style="font-size: 12px;"> 家 </div>
                            </div>
                        </div>
                        <div style="font-size: 16px; color: #666666;">
                            大型三甲医院
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <div style="display: flex; align-items: center;">
                            <div class="color-primary" style=" font-size: 60px; font-weight: bold;">
                                30
                            </div>
                            <div
                                style="display: flex; flex-direction: column; justify-content: space-between; margin-left: 3px;">
                                <div class="color-primary" style="font-size: 18px;"> + </div>
                                <div class="color-primary" style="font-size: 12px;"> 个 </div>
                            </div>
                        </div>
                        <div style="font-size: 16px; color: #666666;">
                            合作客户省市
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <div style="display: flex; align-items: center;">
                            <div class="color-primary" style=" font-size: 60px; font-weight: bold;">
                                40
                            </div>
                            <div
                                style="display: flex; flex-direction: column; justify-content: space-between; margin-left: 3px;">
                                <div class="color-primary" style=" font-size: 18px;"> + </div>
                                <div class="color-primary" style=" font-size: 12px;"> 个 </div>
                            </div>
                        </div>
                        <div style="font-size: 16px; color: #666666;">
                            官方资质认证
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BGImage from '@/assets/ball-background.png';
export default {
    data() {
        return {
            BGImage
        }
    }
}
</script>

<style lang="less" scoped>
.intro-header {
    width: 100%;
    height: 250px;
    width: 100%;
    background: url(../../../assets/company-bg.png);
    background-color: rgba(32, 36, 53, 0.55);
    position: relative;

    .header-title {
        //background: rgba(32, 36, 53, 0.55);
    }
}

.color-primary {
    color: @srims-primary-color;
}
</style>